import React from "react";
import styles from "./Header.module.scss";
import logoClub from '../../assets/logoClub.webp';
import { NavDesktop } from "../nav_desktop/nav_desktop";
import { NavSmartphone } from "../nav_smartphone/nav_smartphone";
import {isMobile} from 'react-device-detect';

function Header() {
  return (
      <header className={styles.main}>
        <div className={styles.container}>
            <img className={styles.logo} src={logoClub} alt="logo club"/>
            
              {isMobile ? <NavSmartphone /> : <NavDesktop />}
            
        </div>
      </header>
  );
}

export default Header;

function burger() {
  let nav = document.querySelector('nav');
  let burger = document.getElementById('burger');
  let bar1 = document.getElementById('bar1');
  let bar2 = document.getElementById('bar2'); 
  let bar3 = document.getElementById('bar3');
  if (nav?.style.display === "block") {
      nav.style.display = "none";
      if (burger != null && bar3 != null && bar1 != null && bar2 != null) {
        burger.style.backgroundColor = "transparent";
        bar3.style.display = "block";
        bar1.style.transform = "rotate(0deg) translate(0, 0)";
        bar2.style.transform = "rotate(0deg) translate(0, 0)";
        bar2.style.marginTop = "0";
      }
      
  } else {
    if (nav != null && bar3 != null && bar1 != null && bar2 != null && burger != null) {
      nav.style.display = "block";
      nav.style.position = "absolute";
      nav.style.top = "0";
      nav.style.left = "0";
      nav.style.width = "100%";
      nav.style.height = "100vh";
      nav.style.backgroundColor = "#0f0f0f";
      bar3.style.display = "none";
      bar1.style.transform = "rotate(-45deg) translate(-9px, 6px)";
      bar2.style.transform = "rotate(45deg) translate(-9px, -6px)";
      bar2.style.marginTop = "18px";
      burger.style.zIndex = "1000 !important";
      nav.addEventListener('click', closeNav);
    }
      
  }

  function closeNav() {
    let nav = document.querySelector('nav');
    let burger = document.getElementById('burger');
    let bar1 = document.getElementById('bar1');
    let bar2 = document.getElementById('bar2'); 
    let bar3 = document.getElementById('bar3');
    if (nav != null && bar3 != null && bar1 != null && bar2 != null && burger != null) {
      nav.style.display = "none";
      burger.style.backgroundColor = "transparent";
      bar3.style.display = "block";
      bar1.style.transform = "rotate(0deg) translate(0, 0)";
      bar2.style.transform = "rotate(0deg) translate(0, 0)";
      bar2.style.marginTop = "0";
  }
  }}

