import {Link} from "react-router-dom";
import styles from "./nav.module.scss";

export function NavDesktop() {
    return (
        <nav className={styles.navDesktop}>
            <ul className={styles.nav}>
                <li className={styles.navItem}><Link to="/">Accueil</Link></li>
                <li className={styles.navItem}><Link to="/club">Le club</Link></li>
                <li className={styles.navItem}><Link to="/rank">Classement</Link></li>
                <li className={styles.navItem}><Link to="/contact">Nous contacter</Link></li>
                <li className={styles.navItem}><Link to="/register">Devenir membre</Link></li>
            </ul>
        </nav>
        
    );
}