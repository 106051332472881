
import React from "react";
import styles from "./Contact.module.scss"
import { useForm } from "react-hook-form";
import axios from 'axios';
import Footer from '../../shared/footer/Footer';
import Header from '../../shared/header/Header';

function Contact() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data: any) => formAction(data)

  console.log(watch("firstName"))
  return (
    <>
    <Header />
      <section className={styles.main}>
        <div className={styles.container}>
          <h2 className={styles.title}>Nous contacter</h2>
          <form className={styles.formContact} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formItem}>
              <label htmlFor="lastName">Nom <span className={styles.red}>*</span></label>
              <p><input type="text" placeholder="Nom" id="lastName" {...register("lastName", {required: true})}/></p>
              {errors.lastName && <span className={styles.red}>Veuillez saisir votre nom !</span>}
            </div>
            <div className={styles.formItem}>
              <label htmlFor="firstName">Prénom <span className={styles.red}>*</span></label>
              <p><input type="text" placeholder="Prénom" id="firstName" {...register("firstName", {required: true})}/></p>
              {errors.lastName && <span className={styles.red}>Veuillez saisir votre nom !</span>}
            </div>
            <div className={styles.formItem}>
              <label htmlFor="email">Adresse mail <span className={styles.red}>*</span></label>
              <p><input type="text" placeholder="Adresse mail" id="email" {...register("email", {required: true})}/></p>
              {errors.lastName && <span className={styles.red}>Veuillez saisir votre nom !</span>}
            </div>
            <div className={styles.formItem}>
              <label htmlFor="message">Message <span className={styles.red}>*</span></label>
              <p><textarea id="message" {...register("message", {required: true})}></textarea></p>
            </div>
            <div className={styles.formItem}>
              <p className={styles.blockSubmit}><input type="submit" name="sendMessage" value="Envoyer" className={styles.button62}/></p>
            </div>
          </form>
        </div>
        </section>
    <Footer />
    </>
  );
}

export default Contact;

async function formAction(data: {firstName: string; lastName: string; email: string; message: string;}) {
  axios.post('https://labuzifpokerclub.fr/api/email.php', {
    form: 'Contact',
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    message: data.message,
  })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });

  alert("Votre message a bien été envoyé !");
}
