import React from 'react'
import styles from './App.module.scss'
import Home from "./view/home/Home"
import Club from "./view/club/Club"
import Rank from "./view/ranking/Ranking"
import Contact from "./view/contact/Contact"
import Register from "./view/register/Registrer"
import {Routes, Route} from "react-router-dom"

function App() {

  return (
    <>
      <div className={styles.main}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/club" element={<Club />} />
                <Route path="/rank" element={<Rank />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/register" element={<Register />} />
            </Routes>
      </div>
    </>
  )
}

export default App
