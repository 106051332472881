import React from 'react';
import styles from './Card_members.module.scss';

interface EmailProps {
  picture: string;
  name: string;
  role: string;
  expression: string;
}
export function Card_members({picture, name, role, expression}: EmailProps) {
  return (
    <div className={styles.card}>
      <figure className={styles.pictures}>
      <img className={styles.photo} src={picture} alt="photo"/>
      </figure>
      <div className={styles.info}>
        <h2 className={styles.name}>{name}</h2>
        <h3 className={styles.function}>{role}</h3>
        <p className={styles.expression}>&quot;{expression}&quot;</p>
      </div>
    </div>
  );
}
