import React from 'react';
import {Link} from "react-router-dom";
import styles from "./Footer.module.scss";
import { SocialIcon } from 'react-social-icons';
import no_minor from "../../assets/no-minor.png";

const socialNetwork = [
    {name: "facebook", url: "https://facebook.com/share/Dn5nU1Mi5DfkMLJR/?mibextid=K35XfP"}
]

const legacies = [
    {path: '/legacy', name: 'Mentions légales'}
]

const copyright = "L'abûzif poker club © 2024"

function Footer() {
    return (
        <footer className={styles.main}>
            <div className={styles.container}>
                <ul className={styles.socialNetwork}>
                    {socialNetwork.map((socialItem, index) => (
                        <li className={styles.socialItem} key={index}><SocialIcon network={socialItem.name} url={socialItem.url} bgColor="none" fgColor="#fff" className={styles.socialIcon} target='_blank'/></li>
                    ))}
                    <li className={styles.socialItem}><img src={no_minor} alt="logo no minor" className={styles.noMinor}/></li>
                </ul>
                <ul className={styles.legacies}>
                    {legacies.map((legacyItem, index) => (
                        <li className={styles.legacyItem} key={index}><Link to={legacyItem.path}>{legacyItem.name}</Link></li>
                    ))}
                </ul>
                <p className={styles.copyright}>{copyright}</p>
            </div>
        </footer>
    );
  }
  
  export default Footer;