
import React from "react";
import styles from "./Registrer.module.scss"
import { useForm } from "react-hook-form";
import axios from 'axios';
import Footer from '../../shared/footer/Footer';
import Header from '../../shared/header/Header';

function Registrer() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm()
    
      const onSubmit = (data: any) => formAction(data)
    
      console.log(watch("firstName"))
  return (
    <>
    <Header />
    <section className={styles.main}>
        <h2 className={styles.title}>Nous rejoindre</h2>
        <div className={styles.container}>
            <div className={styles.condition}>
                <h3>Condition d&apos;adhésion</h3>
                <p>Vous souhaitez rejoindre notre association ?</p>
                <p>Vous pouvez nous envoyer une demande d&apos;adhésion en remplissant le formulaire.</p>
                <p>Notre équipe étudiera votre demande et vous répondra dans les plus brefs délais.</p>
                <p>Si votre demande est acceptée, vous pourrez alors accéder à l&apos;ensemble des services proposés par notre association.</p>
                <p>Vous pourrez également participer à nos événements et à nos activités.</p>
                <p>Le club est limité à 40 membres.</p>
                <p>Les membres actuels sont prioritaire pour les réinscriptions.</p>
                <p>Les places restantes sont attribuées par ordre d&apos;arrivée des demandes.</p>
                <p>Les demandes refusées sont placées sur liste d&apos;attente.</p>
                <p>Les conditions d&apos;adhésion sont les suivantes :</p>
                <p>Vous devez être majeur</p>
                <p>Vous devez avoir une attitude courtoise et respectueuse envers l&apos;ensemble des membres.</p>
                <p>Vous devez respecter le règlement intérieur de l&apos;association.</p>
            </div>
            <form className={styles.formContact} onSubmit={handleSubmit(onSubmit)}>
              <h3 className={styles.title}>Demande d'adhésion</h3>
              <div className={styles.formItem}>
                  <label htmlFor="name">Nom Prénom <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="Nom Prénom" id="name" {...register("name", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir votre nom et prénom !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="pseudo">Pseudo <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="Pseudo" id="pseudo" {...register("pseudo", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir un pseudo !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="email">Adresse mail <span className={styles.red}>*</span></label>
                  <p><input type="email" placeholder="Adresse mail" id="email" {...register("email", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir une adresse mail valide !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="street">N° et voie <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="N° et voie" id="street" {...register("street", {required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir une adresse valide !</span>}
              </div>
              <div className={styles.row}>
                <div className={styles.itemCp}>
                    <label htmlFor="cp">Code Postal <span className={styles.red}>*</span></label>
                    <p><input type="text" placeholder="CP" id="cp" {...register("cp", {required: true})}/></p>
                    {errors.address && <span className={styles.red}>Veuillez saisir un code postal valide !</span>}
                </div>
                <div className={styles.itemCity}>
                    <label htmlFor="city">Ville <span className={styles.red}>*</span></label>
                    <p><input type="text" placeholder="Ville" id="city" {...register("city", {required: true})}/></p>
                    {errors.address && <span className={styles.red}>Veuillez saisir une ville valide !</span>}
                </div>
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="phone">Tél <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="06........" id="phone" {...register("phone", {required: true})}/></p>
                  {errors.address && <span className={styles.red}>Veuillez saisir un numéro de téléphone valide !</span>}
              </div>
              <div className={styles.donateBlock}>
            <p><span>L'inscription est obligatoire</span>, mais la participation est libre</p>
            <p className={styles.small}>Limité à 1 par personne</p>
            <div className={styles.donateButton}>
                <input type="text" placeholder="--,--" id="donate" {...register("donate", {required: true})} />
                <label htmlFor='donate'>€</label>
            </div>
            <span className={styles.separator}></span>
            <p>Participation moyenne constatée</p>
            <p className={styles.price}><span>40€</span></p>
            <p>La participation permet au club d'organiser des événements tout au long de l'année.</p>
        </div>

                <div className={styles.formItemInline}>
                  <input type="checkbox" id="age" {...register("age")} onClick={isChecked}/>
                  <label htmlFor="age">Je certifie avoir plus de 18 ans</label>
                </div>
              <div className={styles.formItem}>
                  <p className={styles.blockSubmit}><input type="submit" name="sendMessage" value="Demande d'adhésion" id="sendMessage" className={styles.button62} disabled/></p>
              </div>
            </form>
        </div>
    </section>
    <Footer />
    </>
  );
}

export default Registrer;

function isChecked() {
  let ageElement = document.getElementById('age') as HTMLInputElement;
  let button = document.getElementById('sendMessage') as HTMLInputElement;
 
    if ( ageElement && ageElement.checked) {
    button.disabled = false;
    }  
}

async function formAction(data: { pseudo: string; name: string; email: string; street: string; cp: string; city: string; phone: string; donate: string;}) {
    axios.post('https://labuzifpokerclub.fr/api/email.php', {
      form: 'Demande d\'adhésion',
      pseudo: data.pseudo,
      email: data.email,
      phone: data.phone,
      donate: data.donate,
      name: data.name,
      street: data.street,
      cp: data.cp,
      city: data.city
    })
    .then(function (response) {
      cancelForm();
      console.log(response);
    })
    .catch(function (error) {
      cancelForm();
      console.log(error);
    });
  
    alert("Votre demande d'adhésion a bien été prise en compte " + JSON.stringify(data.pseudo) + ".");
  }

  function cancelForm() {
    let nameId = document.getElementById('name') as HTMLInputElement;
    nameId.value = '';
    let pseudoId = document.getElementById('pseudo') as HTMLInputElement;
    pseudoId.value = '';
    let emailId = document.getElementById('email') as HTMLInputElement;
    emailId.value = '';
    let streetId = document.getElementById('street') as HTMLInputElement;
    streetId.value = '';
    let phoneId = document.getElementById('phone') as HTMLInputElement;
    phoneId.value = '';
    let cpId = document.getElementById('cp') as HTMLInputElement;
    cpId.value = '';
    let cityId = document.getElementById('city') as HTMLInputElement;
    cityId.value = '';
    let ageId = document.getElementById('age') as HTMLInputElement;
    ageId.checked = false;
    let donateId = document.getElementById('donate') as HTMLInputElement;
    donateId.value = '';
  }
  