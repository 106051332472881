import React from 'react';
import styles from './component.module.scss';
import { useForm } from "react-hook-form";
import axios from 'axios';

interface thursdayProps {
    manche: number;
    day: number;
}

export function FormThursdayComponent({manche,day}: thursdayProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm()
    
      const onSubmit = (data: any) => formAction(data)

      return (
        <div className={styles.thursday}>
          <h1 className={styles.title1}>Saison 1 2024/25</h1>
          <h1 className={styles.title1}>Thursday Night Poker</h1>
          <h2 className={styles.title2}>Manche 6 Day 1</h2>
          <div className={styles.registrer}>
            <form className={styles.formContact} onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.formItem}>
                  <label htmlFor="pseudo">Pseudo <span className={styles.red}>*</span></label>
                  <p><input type="text" placeholder="pseudo" id="pseudo" {...register("pseudo",{required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir votre pseudo !</span>}
              </div>
              <div className={styles.formItem}>
                  <label htmlFor="password">Code <span className={styles.red}>*</span></label>
                  <p><input type="password" placeholder="Code" id="password" {...register("password",{required: true})}/></p>
                  {errors.lastName && <span className={styles.red}>Veuillez saisir le code !</span>}
              </div>
              <div className={styles.formItem}>
                <p className={styles.blockSubmit}>
                  <input type="submit" name="sendMessage" value="Participer" id="sendMessage" className={styles.button62} />
                </p>
              </div>
            </form>
          </div>
        </div>
      );
}

async function formAction(data: { pseudo: string; password: string; manche: number; day: number; }) {
    if (data.password !== 'poker28') {
      alert("Le code est incorrect");
      return;
    }
    
    axios.post('https://labuzifpokerclub.fr/api/email.php',{
      form: 'Thursday Night Poker',
      pseudo: data.pseudo,
      manche: 6,
      day: 1
    })
    .then( (response) => {
      console.log(response);
        cancelForm();
      alert("Votre inscription a bien été prise en compte " + JSON.stringify(data.pseudo) + " \n A jeudi,bonne chance !");
    })
    .catch(function (error) {
      cancelForm();
      console.log(error);
    });
  }
  
  function cancelForm() {
    let pseudoId = document.getElementById('pseudo') as HTMLInputElement;
    pseudoId.value = '';
    let passwordId = document.getElementById('password') as HTMLInputElement;
    passwordId.value = '';
  }