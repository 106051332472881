import {Link} from "react-router-dom";
import styles from "./nav.module.scss";
import { Home, Mail, BarChart2, FileText, Users } from "react-feather";

export function NavSmartphone() {
    return (
        <nav className={styles.navMobile}>
            <ul className={styles.nav}>
                <li className={styles.navItem}><Link to="/"><Home /></Link></li>
                <li className={styles.navItem}><Link to="/club"><Users /></Link></li>
                <li className={styles.navItem}><Link to="/rank"><BarChart2 /></Link></li>
                <li className={styles.navItem}><Link to="/contact"><Mail /></Link></li>
                <li className={styles.navItem}><Link to="/register"><FileText /></Link></li>
            </ul>
        </nav>
        
    );
}